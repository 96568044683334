@import url("https://fonts.googleapis.com/css2?family=Pacifico&display=swap");

.root {
  width: 100% !important;
  position: relative !important;
  background-color: #f9f9f9 !important;
  color: #333 !important;
  font-family: "Roboto", sans-serif;
  border-radius: 20px !important;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  overflow: hidden; /* Prevent horizontal scrollbar */
}

.mediaContainer {
  width: 100%;
  text-align: center;
  padding: 20px 0;
}

.media {
  max-height: 400px;
  width: auto;
  max-width: 100%;
  object-fit: contain;
  border-radius: 10%;
}

.cardTextContent {
  padding: 20px;
  text-align: left;
}

.projectName {
  font-size: 2.5em !important;
  color: #333 !important;
  font-family: "Pacifico", cursive !important;
  text-align: center;
  margin-bottom: 20px;
}

.projectDescription,
.projectAchievements,
.projectKeyFeatures,
.projectUseCase {
  font-size: 1.1em !important;
  color: #555 !important;
}

.projectKeyFeatures ul,
.projectAchievements ul,
.techList {
  list-style-type: disc;
  margin-left: 40px;
  padding-left: 20px;
}

.descriptionTitle {
  font-weight: bold !important;
  font-size: 1.2em !important;
  margin-top: 10px;
  color: #222 !important;
}

/* Media Queries for smaller devices */
@media (max-width: 768px) {
  .media {
    max-height: 250px;
  }

  .projectName {
    font-size: 2em !important;
  }

  .projectDescription,
  .projectAchievements,
  .projectKeyFeatures,
  .projectUseCase {
    font-size: 1em !important;
  }

  .descriptionTitle {
    font-size: 1.1em !important;
  }
}
